<template>
<div>
  <spinner v-if="!options" />
  <b-form-select v-else v-model="selected" @change="sel_changed" :options="options">
    <template #first>
      <b-form-select-option :value="null" disabled>-- Veikala --</b-form-select-option>
    </template>
  </b-form-select>
</div>
</template>

<script>
import Spinner from '@/components/Spinner.vue';
export default {
  components: {Spinner},
  props: ["value"],
  data(){return{
    options: null,
    selected: this.value
  }},
  watch: {
    value: function(newVal) {
      if (newVal)
        {this.selected=newVal.value}
      else
        {this.selected=null}
    }
  },
  methods: {
    read_cats(){
      this.backend.get("/woo/categories").then(resp => 
      {
        this.options = resp.data.map(el=>{ return{'value': el.id,'text': el.name} })
      });
    },
    sel_changed(val){
      this.$emit('input',this.options.find( el => el.value === val))
    }
  },
  created(){
    this.read_cats()
  }
}
</script>