<template>
  <b-form-group
        :label="name"
        :label-for="name"
      >
        <b-form-input
          v-model="new_val"
          :lazy="true"
          type="text"
          @update="update_conf"
          required
          :state="inp_state"
        ></b-form-input>
      </b-form-group>
</template>

<script>
export default {
  props: ["name","value","cat"],
  data(){return{
    inp_state: null,
    new_val: null
  }},
  methods: {
    update_conf(){
      this.inp_state = null
      this.backend.put("/config/"+this.cat+"/"+this.name,{value: this.new_val}).then((resp)=>{
        if (resp.data && resp.data=='OK')
         {this.inp_state=true}
      }).catch(()=>{this.inp_state=false});
    }
  },
  created(){
    this.new_val = this.value;
  }
}
</script>