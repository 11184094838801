<template>
  <div class="row justify-content-center">
  <div class="col-12" v-if="cvalues">
    <b-card :title="title" class="col-12">
      <config-field :key="idx" v-for="(val,idx) in cvalues" :name="idx" :cat="category" :value="val"/>
    </b-card>
  </div>
  </div>
</template>

<script>
import ConfigField from '@/components/ConfigField.vue'
export default {
  props: ["title","category"],
  data(){return{
    cvalues: null
  }},
  methods: {
    load_cval(){
      this.backend.get("/config/"+this.category).then(resp => 
      {
        if (resp.data)
         {this.cvalues = resp.data}
      });
    }
  },
  created(){
    this.load_cval()
  },
  components: {ConfigField}
}
</script>

<style scoped>
>>> .form-group label {color:rgb(67, 90, 219)}
</style>>

